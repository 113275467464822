@import "../../../styles/constants.scss";

.cardWrapper {
  
  .card {
    transition: all 1200ms ease-in-out;
    position: relative;
    div.MuiCardContent-root {
      padding: 2rem;
    }
    .cardTitle {
      &:before {
        content: '';
        height: 0.125rem;
        width: 20%;
        background-color: #fff;
        display: block;
        margin: 0 auto .4rem;
      }
    }
  }
}
