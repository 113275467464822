@import "./styles/constants.scss";

.App {
  text-align: center;
  position: relative;
  main {
    padding-top: 3.75rem;
    padding-bottom: 3.5rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    @media (min-width: $breakpoint-mobile-xs) {
      margin: auto;
      padding-top: 7.625rem;
      padding-bottom: 0;
    }
    @media (min-width: $breakpoint-tablet) {
      padding-top: 8.75rem;
      padding-bottom: 0;
    }
  }
}
