@import-normalize;
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: "PrimaryRegular";
  src: local('PrimaryRegular'), url("./assets/fonts/Antonio-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "PrimaryMedium";
  src: local('PrimaryMedium'), url("./assets/fonts/Antonio-Medium.ttf") format('truetype');
}

@font-face {
  font-family: "PrimarySemiBold";
  src: local('PrimarySemiBold'), url("./assets/fonts/Antonio-SemiBold.ttf") format('truetype');
}

@font-face {
  font-family: "PrimaryBold";
  src: local('PrimaryBold'), url("./assets/fonts/Antonio-Bold.ttf") format('truetype');
}

@font-face {
  font-family: "PrimaryLight";
  src: local('PrimaryLight'), url("./assets/fonts/Antonio-Light.ttf") format('truetype');
}

@font-face {
  font-family: "PrimaryExtraLight";
  src: local('PrimaryExtraLight'), url("./assets/fonts/Antonio-ExtraLight.ttf") format('truetype');
}

@font-face {
  font-family: "PrimaryThin";
  src: local('PrimaryThin'), url("./assets/fonts/Antonio-Thin.ttf") format('truetype');
}

html * {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'PrimaryRegular', 'Roboto',
    'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
