@import "../../../styles/constants.scss";

.contact {
  flex-grow: 1;
  text-align: center;
  background-color: $secondary-color;
  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-mobile-xs) {
    padding: 1.5rem 0;
  }
  @media (min-width: $breakpoint-mobile) {
    padding: 3rem;
    text-align: left;
    background-color: white;
  }
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (min-width: $breakpoint-mobile) {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .pageTitle {
    font-weight: 100;
    letter-spacing: -0.125rem;
    color: white;
    @media (min-width: $breakpoint-mobile) {
      margin: 0;
      margin-bottom: 0.9375rem;
      color: black;
    }
    &::after {
      display: block;
      content: "";
      height: 0.125rem;
      width: 4rem;
      margin-left: 50%;
      transform: translateX(-50%);
      background-color: $primary-color;
      @media (min-width: $breakpoint-mobile) {
        width: 5rem;
        margin-left: 0.3125rem;
        transform: translateX(0%);
      }
    }
    @media (min-width: $breakpoint-mobile) {
      margin-bottom: 1.875rem;
    }
  }
  .leftWrapper {
    align-items: center;
    padding: 0 0.9375rem;
    background-color: $secondary-color;

    @media (min-width: $breakpoint-mobile) {
      flex-basis: 40%;
      padding: 0;
      background-color: white;
    }
    .listItem {
      padding: 0 0 0.625rem;
      background-color: $secondary-color;
      color: white;
      @media (min-width: $breakpoint-mobile) {
        padding: 0 0 1.875rem;
        background-color: white;
        color: black;
      }
    }
    .listItemAvatar {
      width: 2.125rem;
      height: 2.125rem;
      background-color: $primary-color;
      @media (min-width: $breakpoint-tablet) {
        width: 4rem;
        height: 4rem;
      }
    }
    .listItemText {
      span,
      p {
        margin: 0;
        color: white;
        @media (min-width: $breakpoint-mobile) {
          color: black;
        }
      }
      span {
        text-align: left;
      }
    }
    ul {
      background-color: $secondary-color;
      @media (min-width: $breakpoint-mobile) {
        background-color: white;
      }
    }
  }
  .rightWrapper {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    @media (min-width: $breakpoint-mobile) {
      flex-grow: 2;
      flex-basis: 70%;
    }
    .mapouter {
      position: relative;
      flex-grow: 1;
      display: inherit;
      @media (min-width: $breakpoint-mobile-xs) {
        height: 25rem;
      }
      @media (min-width: $breakpoint-tablet) {
        height: 37.5rem;
      }

      .gmap_canvas {
        flex-grow: 1;
        iframe {
          display: block;
          overflow: hidden;
          background: none !important;
          height: 100%;
          width: 100%;
          @media (min-width: $breakpoint-mobile-xs) {
            height: 25rem;
          }
          @media (min-width: $breakpoint-tablet) {
            height: 37.5rem;
          }
        }
      }
    }
  }
}

.loaderWrapper {
  position: relative;
  height: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    display: block;
    z-index: 5;
  }
}
