@import "../../../styles/constants.scss";

.about {
  background-color: rgb(18,18,18);
  .pageTitle {
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
    padding: 1rem;
    letter-spacing: -0.125rem;
    color: #fff;
    @media (min-width: $breakpoint-desktop) {
      text-align: left;
    }
    &:after {
      content: "";
      height: 0.125rem;
      width: 4rem;
      background-color: $primary-color;
      display: block;
      margin: 0.4rem auto;
      @media (min-width: $breakpoint-desktop) {
        text-align: left;
        margin: 0.4rem 0;
        width: 5rem;
      }
    }
  }
  .pageSubtitle {
    margin: 1rem;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: -0.125rem;
    @media (min-width: $breakpoint-mobile) {
      text-align: left;
    }
    &:after {
      content: "";
      height: 0.125rem;
      width: 4rem;
      background-color: #fff;
      display: block;
      margin: 0.4rem auto;
      @media (min-width: $breakpoint-mobile) {
        margin: 0.4rem 0;
        width: 5rem;
      }
    }
  }
  .textBold {
    font-weight: 700;
    text-align: center;
    @media (min-width: $breakpoint-mobile) {
      text-align: left;
    }
  }
  .list {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: $primary-color;
    &Item {
      text-transform: capitalize;
      span {
        color: white;
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: 1rem;
      }
    }
  }
  .copyBlackWrapper,
  .copyPrimaryWrapper {
    margin: 0;
    padding: 1.5rem .5rem;
    @media (min-width: $breakpoint-mobile) {
      padding: 1.5rem 6.25rem;
    }
    @media (min-width: $breakpoint-tablet) {
      padding: 2.25rem 12.375rem;
    }
    @media (min-width: $breakpoint-desktop) {
      padding: 3rem 15.5rem;
    }
    .contentWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      @media (min-width: $breakpoint-desktop) {
        flex-direction: row-reverse;
        align-items: unset;
        justify-content: stretch;
      }
    }
  }
  .copyBlackWrapper {
    background-color: $secondary-color;
    color: #fff;
    .pageSubtitle {
      &:after {
        background-color: $primary-color;
      }
    }
    .textContentWrapper {    
      // @media (min-width: $breakpoint-desktop) {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   background-color: rgba(0,0,0,.8);
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   bottom: 0;
      // }
    }
  }
  .copyPrimaryWrapper {
    background-color: $primary-color;
    color: #000;
    .contentWrapper {
      @media (min-width: $breakpoint-desktop) {
        flex-direction: row
      }
    }
    .textContentWrapper {}
  }
  .image {
    width: 100%;
    height: auto;
    @media (min-width: $breakpoint-mobile) {
      width: 90%;
    }
    @media (min-width: $breakpoint-tablet) {
      width: 90%;
    }
    @media (min-width: $breakpoint-desktop) {
      width: 60%;
    }
  }
}
