@import "../../../styles/constants.scss";

.datasecurity {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media (min-width: $breakpoint-mobile) {
    padding: 1.5rem 6.25rem;
  }
  @media (min-width: $breakpoint-tablet) {
    padding: 2.25rem 12.375rem;
  }
  @media (min-width: $breakpoint-desktop) {
    padding: 3rem 15.5rem;
  }
  .pageTitle {
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
    padding: 1rem;
    letter-spacing: -0.125rem;
    &:after {
      content: "";
      height: 0.125rem;
      width: 4rem;
      background-color: $primary-color;
      display: block;
      margin: 0.4rem auto 0;
    }
  }
  .pageSubtitle {
    margin: 1rem;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: -0.125rem;
    @media (min-width: $breakpoint-mobile) {
      text-align: left;
    }
    &:after {
      content: "";
      height: 0.125rem;
      width: 4rem;
      background-color: $primary-color;
      display: block;
      margin: 0.4rem auto;
      @media (min-width: $breakpoint-mobile) {
        margin: 0.4rem 0;
        width: 5rem;
      }
    }
  }
  .list {
    text-align: left;
    padding: 1.3rem;
    &Item {
      span {
        text-align: left;
        margin: 0;
        padding: 0;
        font-size: 1rem;
      }
    }
  }
}