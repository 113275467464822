@import "../../../styles/constants.scss";

.home {
  .pageTitle {
    color: #fff;
    margin: 0;
    padding: 1rem 0 0;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: -0.125rem;
    text-shadow: 0px 0px 3px black;
    @media (min-width: $breakpoint-mobile) {
      padding: 1rem 0;
      color: unset;
      text-shadow: 0px 0px 3px white;
    }
    &:after {
      content: "";
      height: 0.125rem;
      width: 4rem;
      background-color: $primary-color;
      display: block;
      margin: 0.4rem auto 0;
    }
  }
  .pageSubtitle {
    margin: 1rem;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: -0.125rem;
    @media (min-width: $breakpoint-mobile) {
      text-align: center;
    }
    &:after {
      content: "";
      height: 0.125rem;
      width: 4rem;
      background-color: $primary-color;
      display: block;
      margin: 0.4rem auto;
      @media (min-width: $breakpoint-mobile) {
        margin: auto;
        width: 5rem;
      }
    }
  }
  .gallery {
    width: 100%;
    @media (min-width: $breakpoint-mobile) {
      padding: 1.5rem 6.25rem 1.5rem 0px;
    }
    @media (min-width: $breakpoint-tablet) {
      padding: 1.5rem 7.75rem 1.5rem 1.5rem;
    }
    @media (min-width: $breakpoint-desktop) {
      padding: 3rem 15.5rem 3rem 9.25rem;
    }
  }
  .logo_wrapper {
    background-repeat: repeat-x;
    background-color: #000;
    position: relative;
    z-index: 0;
    @media (min-width: $breakpoint-mobile) {
      background-color: #fff;
    }
    .logo {
      height: 5rem;
    }
  }
  
  .product_wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-bottom: 1.5rem;
  
    @media (min-width: $breakpoint-mobile) {
      justify-content: flex-start;
    }
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
  
  .partnersWrapper {
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    padding: 2rem 1rem;
    margin: 0;
    @media (min-width: $breakpoint-mobile) {
      padding: 4rem 1rem;
    }
    .partnerLogoWrapper {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      .logoContainer {
        height: 4.1563rem;
        width: 6.25rem;
        margin: 0.5rem;
        @media (min-width: $breakpoint-mobile) {
          height: 5.5412rem;
          width: 8.3331rem;
        }
        @media (min-width: $breakpoint-tablet) {
          height: 8.3125rem;
          width: 12.5rem;
        }
        .logo {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  
  .toldAboutUs {
    .quoteWrapper {
      display: flex;
      flex-direction: column;
      @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
      }
      .quote {
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 0.3125rem;
        padding: 1rem 1rem;
        margin: 1rem 1rem;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $primary-color;
    min-height: 3.125rem;
    padding: 0.25rem;
    color: #fff;
    @media (min-width: $breakpoint-tablet) {
      padding: 0.5rem 0.75rem;
    }
    .footerText {
      margin: 0;
    }
    .footerLink {
      color: inherit;
      text-decoration: none;
    }
  }
}

