@import "../../../styles/constants.scss";

.topBar {
  display: flex;
  justify-content: space-between;
  font-family: 'PrimaryLight';
  padding: 0.5rem 1rem;
  background-color: $primary-color;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #fff;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  @media (min-width: $breakpoint-mobile) {
    border-bottom: none;
  }
  .topText {
    @media (min-width: $breakpoint-mobile-xs) {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
    @media (min-width: $breakpoint-mobile) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
    @media (min-width: $breakpoint-tablet) {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  }
}

.header {
  background-color: #000;
  font-family: 'PrimaryLight';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  left: 0;
  right: 0;
  &__mobile {
    position: fixed;
    bottom: 0;
    z-index: 9999;
  }
  &__tablet {
    position: fixed;
    padding: 0.5rem 1.5rem;
    justify-content: space-between;
    flex-direction: row;
    bottom: unset;
    top: 2.25rem;
    z-index: 998;
    .logoWrapper {
      display: flex;
      align-items: center;
      .logo {
        width: 4.375rem;
        height: 4.375rem;
      }
      .logo__sticky {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  &__bottom {
    background-color: #000;
  }
  &__desktop {
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
    position: fixed;
    z-index: 999;
    top: 2.5rem;
    left: 0;
    right: 0;
    padding: 0rem 3rem;

    .logoWrapper {
      display: flex;
      align-items: center;
      .logo {
        width: 6.25rem;
        height: 6.25rem;
      }
      .logo__sticky {
        width: 4.375rem;
        height: 4.375rem;
      }
    }
  }
}

.navigation {
  &__desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
  }
}


.title {
  font-size: 1rem;
  margin: 0;
  @media (min-width: $breakpoint-mobile) {
    font-size: 2rem;
  }
  @media (min-width: $breakpoint-tablet) {
    font-size: 3rem;
  }
}
.link {
  text-decoration: none;
}
.hidden {
  display: none;
}
