@import "../../../styles/constants.scss";

.sales {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .pageTitle {
    margin: 0;
    text-transform: uppercase;
    font-weight: 300;
    padding: 1rem;
    letter-spacing: -0.125rem;
    &:after {
      content: "";
      height: 0.125rem;
      width: 4rem;
      background-color: $primary-color;
      display: block;
      margin: 0.4rem auto 0;
    }
  }
}
